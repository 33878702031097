<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>

    <SlideLayout :tabs="slideTabs">
      <template v-slot:add_fail>
        <CreateFormLayout>
          <template v-slot:title__text>
            Массовое добавление лицевых счетов
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <div class="instruction">
            <p class="instruction__title">Правила заполнения файла:</p>
            <div class="instruction__description">
              <ol>
                <li>
                  Каждая строка должна содержать номер лицевого счёта и
                  количество пользователей (не более 10), записанные в разные
                  столбцы.
                </li>
                <li>
                  Не допускаются пустые строки.
                </li>
                <li>
                  Пример заполнения
                  <a href="/img/images/documentations/upload_createAccount.png"
                    >по ссылке.</a
                  >
                </li>
              </ol>
            </div>
          </div>

          <template>
            <!--    Данные компании    -->
            <div>
              <FormInputBlock>
                <ValidationFileField
                  v-model="file"
                  :rules="'required'"
                  accept=".xlsx"
                  label="Выбрать файл"
                  validate-name="Выбрать файл"
                />
                <ValidationAutocompleteField
                  :search-function="objectSearch"
                  :disabled="!!objectId"
                  label="Объект"
                  validate-name="Объект"
                  rules="required"
                  v-model="object_file"
                />
                <ValidationAutocompleteField
                  multiple
                  hide-selected
                  :search-function="group_uploadSearch"
                  label="Группа"
                  :disabled="!object_file"
                  v-model="group_upload"
                  return-object
                >
                  <template v-slot:selection=""></template>
                </ValidationAutocompleteField>
              </FormInputBlock>
            </div>
            <div class="">
              <BasicTable
                :header="[
                  { text: 'ID', value: 'value' },
                  { text: 'Название', value: 'text' },
                  {
                    text: 'Удалить',
                    width: 10,
                    renderFunction: () => `<a class='form__btn-delete'></a>`
                  }
                ]"
                :table-data="group_upload"
                @row_click="deleteAccountFile($event.item.value)"
              ></BasicTable>
            </div>
            <!--    Данные компании    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              type="submit"
              @click="handleSubmit(uploadAccounts)"
              class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
      </template>
      <template v-slot:manual_input>
        <CreateFormLayout>
          <template v-slot:title__text>
            Добавление лицевых счетов
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <template>
            <!--    Данные компании    -->
            <div>
              <FormInputBlock>
                <ValidationAutocompleteField
                  :search-function="objectSearch"
                  :disabled="!!objectId"
                  label="Объект"
                  validate-name="Объект"
                  rules="required"
                  v-model="object_manual"
                />

                <!--                <ValidationAutocompleteField-->
                <!--                  :search-function="group_manualSearch"-->
                <!--                  label="Группа"-->
                <!--                  validate-name="Группа"-->
                <!--                  v-model="group_create"-->
                <!--                />-->
                <ValidationAutocompleteField
                  multiple
                  hide-selected
                  :search-function="group_manualSearch"
                  label="Группа"
                  :disabled="!object_manual"
                  return-object
                  v-model="group_create"
                >
                  <template v-slot:selection=""></template>
                </ValidationAutocompleteField>
              </FormInputBlock>
              <div class="">
                <BasicTable
                  :header="[
                    { text: 'ID', value: 'value' },
                    { text: 'Название', value: 'text' },
                    {
                      text: 'Удалить',
                      width: 10,
                      renderFunction: () => `<a class='form__btn-delete'></a>`
                    }
                  ]"
                  :table-data="group_create"
                  @row_click="deleteAccount($event.item.value)"
                ></BasicTable>
              </div>

              <FormInputBlock>
                <ValidationInputField
                  v-model="accountNumber"
                  label="Номер л/с"
                  validate-name="Номер л/с"
                />
                <ValidationInputField
                  v-model="maxUsers"
                  label="Макс. количество пользователей (не более 10)"
                  validate-name="Макс. количество пользователей (не более 10)"
                />
                <v-btn
                  outlined
                  class="btn btn-green"
                  @click="addNumber(accountNumber, maxUsers, group_create)"
                  >Добавить</v-btn
                >
              </FormInputBlock>
              <table
                class="table table_overflow_hidden table-physical_accounts"
              >
                <thead>
                  <tr>
                    <th>Номер л/с</th>
                    <th>Макс. пользователей</th>
                    <th>Группы</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(accountNumber, index) in accountNumbers"
                    class="item"
                    :key="index"
                  >
                    <td>{{ accountNumber.number }}</td>
                    <td>{{ accountNumber.max_users }}</td>
                    <td>
                      <span
                        v-for="(group,
                        groupIndex) in accountNumber.group_create"
                        :key="groupIndex"
                      >
                        {{ group.text }}
                        <br />
                      </span>
                    </td>
                    <td class="btn-delete-wrap">
                      <button
                        @click.stop="deleteNumber(index)"
                        class="form__btn-delete"
                      ></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--    Данные компании    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              :disabled="!object_manual || accountNumbers.length == 0"
              type="submit"
              @click="handleSubmit(createAccounts)"
              class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
      </template>
    </SlideLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";

import { getObjectsClearRequest } from "@/helpers/api/objects";
import { getAccessGroupsRequest } from "@/helpers/api/access-group";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import BasicTable from "@/components/tables/BasicTable.vue";

export default {
  name: "CreateAccountPage",
  props: {
    objectId: {
      type: [String, Number]
    }
  },
  components: {
    BasicTable,
    ValidationAutocompleteField,
    ValidationFileField,
    ValidationInputField,
    FormInputBlock,
    CreateFormLayout,
    SlideLayout,
    MainLayout
  },

  data() {
    return {
      loading: false,
      group_uploadSearch: async value => {
        return (
          await getAccessGroupsRequest({
            query: { ids: value, object: this.objectId || this.object_file }
          })
        ).data.results.map(el => ({
          text: el.title,
          value: el.id
        }));
      },
      group_manualSearch: async value => {
        return (
          await getAccessGroupsRequest({
            query: {
              ids: value,
              object: this.objectId || this.object_manual
            }
          })
        ).data.results.map(el => ({
          text: el.title,
          value: el.id
        }));
      },
      objectSearch: async value => {
        return (
          await getObjectsClearRequest({
            query: { ids: value, limit: 100000 }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      slideTabs: [
        {
          text: "Добавить вручную",
          value: "manual_input",
          icon: null,
          count: null
        },
        {
          text: "Загрузить Файл XLSX",
          value: "add_fail",
          icon: null,
          count: null
        }
      ]
    };
  },
  computed: {
    group_create: {
      get() {
        return this.$store.getters.getCreateAccountManualForm.group;
      },
      set(newValue) {
        this.$store.commit("setCreateAccountManualForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },

    group_upload: {
      get() {
        return this.$store.getters.getCreateAccountFileForm.group;
      },
      set(newValue) {
        this.$store.commit("setCreateAccountFileForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    file: {
      get() {
        return this.$store.getters.getCreateAccountFileForm.file;
      },
      set(newValue) {
        this.$store.commit("setCreateAccountFileForm", {
          fieldName: "file",
          value: newValue
        });
      }
    },
    object_file: {
      get() {
        return this.$store.getters.getCreateAccountFileForm.object;
      },
      set(newValue) {
        this.$store.commit("setCreateAccountFileForm", {
          fieldName: "object",
          value: newValue
        });
      }
    },
    object_manual: {
      get() {
        return this.$store.getters.getCreateAccountManualForm.object;
      },
      set(newValue) {
        if (newValue !== this.object_manual) {
          this.group_create = [];
          this.group_upload = [];
        }
        this.$store.commit("setCreateAccountManualForm", {
          fieldName: "object",
          value: newValue
        });
      }
    },

    accountNumber: {
      get() {
        return this.$store.getters.getCreateAccountManualForm.accountNumber
          .number;
      },
      set(newValue) {
        this.$store.commit("SET_CREATE_ACCOUNT_MANUAL_FORM_ACCOUNT_NUMBER", {
          fieldName: "number",
          value: newValue
        });
      }
    },
    maxUsers: {
      get() {
        return this.$store.getters.getCreateAccountManualForm.accountNumber
          .max_users;
      },
      set(newValue) {
        let pattern = /[^0-9]+/g; // только цифры
        let value = newValue.replace(pattern, "");
        if (value > 10) {
          value = 10;
        }
        this.$store.commit("SET_CREATE_ACCOUNT_MANUAL_FORM_ACCOUNT_NUMBER", {
          fieldName: "max_users",
          value: value
        });
      }
    },
    accountNumbers: {
      get() {
        return this.$store.getters.getCreateAccountManualForm.accountNumbers;
      },
      set(newValue) {
        this.$store.commit("setCreateAccountManualForm", {
          fieldName: "accountNumbers",
          value: newValue
        });
      }
    }
  },
  mounted() {
    if (this.objectId) {
      this.object_manual = Number(this.objectId);
      this.object_file = Number(this.objectId);
    }
  },
  beforeDestroy() {
    this.$store.commit("clearCreateAccountForm");
  },
  methods: {
    deleteAccount(id) {
      this.group_create = this.group_create.filter(el => el.value !== id);
    },
    deleteAccountFile(id) {
      this.group_upload = this.group_upload.filter(el => el.value !== id);
    },
    addNumber(number, max_users, group_create) {
      if (number && max_users) {
        this.$store.commit("addAccountNumber", {
          number,
          max_users,
          group_create
        });
        this.accountNumber = "";
        this.maxUsers = "";
        this.group_create = "";
      }
    },
    deleteNumber(id) {
      this.$store.commit("deleteAccountNumber", { id }).then(() => {
        this.$store.dispatch(
          "successNotification",
          actionMessage.remove(successMessageDictionary.account)
        );
      });
    },
    uploadAccounts() {
      this.loading = true;
      this.$store
        .dispatch("uploadAccounts")
        .finally(() => (this.loading = false));
    },
    createAccounts() {
      this.loading = true;
      this.$store
        .dispatch("createAccounts")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.account)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
